<template>
  <svg viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1098 3.92662C11.15 4.23782 11.204 4.54822 11.2201 4.86101C11.2442 5.32701 11.2458 5.7938 11.2482 6.2606C11.2523 7.27358 11.249 8.28655 11.2515 9.29953C11.2531 9.76077 11.3891 10.1783 11.699 10.53C12.0049 10.8769 12.4026 10.9944 12.8566 10.9587C13.6753 10.8928 14.296 10.4792 14.7846 9.85921C14.985 9.60517 15.1404 9.3162 15.3175 9.0439C15.3256 9.03199 15.3409 9.02406 15.3682 9.00103C15.3578 9.11694 15.3497 9.21538 15.3392 9.31382C15.2483 10.1426 14.9786 10.9087 14.4908 11.5906C14.1607 12.0527 13.7421 12.4234 13.2189 12.6679C12.8349 12.8473 12.4292 12.9474 12.0049 12.9704C11.6048 12.9918 11.2064 12.9704 10.8216 12.8521C10.3804 12.7171 9.99968 12.4837 9.69136 12.1416C9.32348 11.7335 9.10452 11.2509 8.96767 10.7277C8.84853 10.2736 8.80747 9.81078 8.80908 9.3424C8.8123 8.02775 8.81472 6.7131 8.80989 5.39925C8.80828 4.94674 8.74629 4.50059 8.58288 4.07508C8.38002 3.54636 8.05238 3.11847 7.51384 2.8803C7.20311 2.74297 6.87547 2.70168 6.53496 2.72629C5.9457 2.76916 5.42567 2.99145 4.93623 3.30026C4.92576 3.30661 4.91288 3.30899 4.90161 3.31296C4.51682 2.59769 4.13606 1.88956 3.74805 1.16714C3.86075 1.09013 3.9823 0.999629 4.1111 0.918655C4.54822 0.643182 5.01431 0.426456 5.51422 0.293086C6.13165 0.128755 6.75955 0.0835044 7.39711 0.15654C7.77466 0.200203 8.14576 0.273239 8.50077 0.405021C9.07554 0.618572 9.57303 0.948028 9.97875 1.40768C10.3821 1.86415 10.6638 2.38652 10.8578 2.95731C10.9117 3.11608 10.9608 3.27645 11.0075 3.4376"
      fill="currentColor"
    />
    <path
      d="M0.148304 7.67291C0.365654 6.40748 0.768959 5.21509 1.3711 4.05922C1.4041 4.14813 1.43308 4.21243 1.4524 4.27991C1.56913 4.68082 1.70034 5.07934 1.79372 5.4858C1.88469 5.88035 1.94667 6.28205 2.00302 6.68375C2.08513 7.26724 2.07628 7.8555 2.03361 8.44217C2.01993 8.63508 1.98451 8.8264 1.97002 9.01931C1.94989 9.27176 1.9668 9.52104 2.0964 9.74808C2.13343 9.81318 2.17932 9.87669 2.23406 9.9275C2.37896 10.0617 2.52144 10.0291 2.62046 9.85049C2.73638 9.64091 2.7887 9.41307 2.82251 9.18126C2.92797 8.45249 2.94729 7.72054 2.86035 6.98859C2.81527 6.60754 2.77582 6.22569 2.70579 5.8486C2.61804 5.37942 2.51822 4.91025 2.39264 4.4498C2.2091 3.77898 2.01188 3.11054 1.79453 2.44925C1.59489 1.83956 1.35741 1.24257 1.06842 0.667016C0.996774 0.52412 0.966989 0.370903 1.02897 0.20816C1.09096 0.044623 1.30348 -0.0347639 1.47897 0.014456C1.73174 0.0851103 1.92735 0.239121 2.0956 0.429649C2.52547 0.917879 2.88128 1.4585 3.19764 2.02136C3.45766 2.48418 3.70962 2.95415 3.93261 3.43524C4.13708 3.87663 4.30935 4.3339 4.47437 4.79116C4.60398 5.15079 4.71587 5.51914 4.80764 5.88988C4.897 6.24871 4.9614 6.61548 5.01775 6.98145C5.12964 7.7126 5.13126 8.44693 5.06363 9.18443C5.01292 9.73538 4.92678 10.2784 4.76015 10.8071C4.56856 11.4152 4.29164 11.9789 3.8183 12.429C3.52608 12.7068 3.18396 12.9093 2.77985 12.9577C2.54076 12.9863 2.29041 12.9648 2.05132 12.9291C1.86215 12.9005 1.68183 12.8132 1.49668 12.7545L1.45321 12.7354L1.42825 12.7235L1.41537 12.7164L1.39927 12.7076L1.37029 12.6878L1.33568 12.6687C1.33568 12.6687 1.30589 12.6497 1.28818 12.6401C1.22217 12.602 1.15455 12.5631 1.10062 12.5107C1.06761 12.4782 1.00563 12.4322 1.00563 12.4322C1.00563 12.4322 0.937204 12.3853 0.900174 12.3385C0.797134 12.2067 0.682824 12.0821 0.591859 11.9431C0.321379 11.5303 0.181309 11.0691 0.0991988 10.5896C-0.0287962 9.83938 -0.0231612 9.08599 0.0605588 8.33182"
      fill="currentColor"
    />
  </svg>
</template>
